<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        {{ inputs.language === 'en' ? 'For' : 'Pour' }}
        <number-value :value="molLR" unit="\text{mol}" />
        {{
          inputs.language === 'en'
            ? 'of limiting reagent, what is the minimum amount of'
            : 'de réactif limitant, quelle est la quantité minimale de'
        }}
        <chemical-latex content="NaBH4" />
        {{
          inputs.language === 'en'
            ? 'that can be used to achieve a complete conversion?'
            : 'qui peut être utilisée pour obtenir une conversion complète ?'
        }}
      </p>
      <!--  éè-->

      <calculation-input
        v-model="inputs.massNaBH4"
        prepend-text="$\text{Mass}:$"
        append-text="$\text{g}$"
        class="mb-4"
        :disabled="!allowEditing"
      />

      <v-btn-toggle
        v-model="inputs.language"
        class="ml-2"
        size="small"
        dense="dense"
        rounded="rounded"
        mandatory
      >
        <v-btn :value="'en'">English</v-btn>
        <v-btn :value="'fr'">Français</v-btn>
      </v-btn-toggle>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '@/tasks/components/NumberValue.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'uOttawa1321Prelab4Q1',
  components: {
    ChemicalLatex,
    StembleLatex,
    NumberValue,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        massNaBH4: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
    };
  },
  computed: {
    molLR() {
      return this.taskState.getValueBySymbol('molLR').content;
    },
  },
};
</script>
